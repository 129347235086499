.price-refinement{
    .noUi-handle.noUi-handle-lower{
        width:16px;
        height:16px;
        border-radius:50px;
        border-width: 2px;
        border-color: #1e1e1e;
    }
}
.price-refinement{
    .noUi-handle.noUi-handle-upper{
        width:16px;
        height:16px;
        border-radius:50px;
        border-width: 2px;
        border-color: #1e1e1e;
    }
}
.noUi-horizontal{
    .noUi-handle{
        right: -1px;
    }
}
#priceslider{
            height:8px;
            width: 193px;
            margin-top: 22px;
            background-color: #e7e7e7;
            padding-left: 14px;
       }
.price-value{
           padding:10px 0 2px 0;
       }
.noUi-base{
.noUi-connects{
.noUi-connect{
    background-color: #1e1e1e;
}
}
}
.noUi-handle{
    &:before {
      background-color:rgba(0,0,0,0);

    }
    &:after{
        background-color:rgba(0,0,0,0);
    }
}  
