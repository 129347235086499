
.search-results {
	padding-bottom: 0;
    @include media-query(small-up) {
    	margin-bottom: 5.5rem;	
    }
}

    .search-results__title {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .search-results__container {
        min-height: 30rem;
    }

    .search-results__footer {
        position: relative;
        margin-top: 3.25rem;
    }

    .search-results__section {
        position: relative;

        &:not(.toggle--active) {
            display: none;
        }
    }

.search-results--null-page {
    margin-top: 3rem;
    margin-bottom: 3rem;
    .search-results--null__title{
        word-break: break-word;
    }
}

.search-results--null__form  {
    position: relative;
    margin-top: 1.75rem;
}

.search-results--null__description {
    margin-top: .625rem;
}

.search-results--null__help {
    margin-top: 2.125rem;
}
.search-results--null-page {
	.content-asset {
		padding-bottom: 24px;
		margin: 0 auto;
		width: fit-content;
	}
	.search-results--null__title {
        text-transform: inherit;
		word-break: break-word;
		font-size: 42px;
		font-weight: 400;
		margin-bottom: 2.25rem;
	}
}
.search-results--null__help {
	display: flex;
	font-size: $font-size-deci;
	justify-content: center;
}
.search-results--null__description {
	font-weight: 400;
	font-size: 21px;
	margin-top: 59px;
    text-align: center;
	a {
		text-decoration: underline;
		text-transform: lowercase;
		font-weight: normal;
	}
}
.search-results__main {
	padding-bottom: 30px;
	@include media-query(small-up) {
    	margin-bottom: 60px;
    }
	.slick-dots {
		margin: 40px 0 0 0;
		justify-content: center;
	}
}
.not-found--category {
    color: $color-link-blue;
}

.null-page {
    .module-container {
        .product-list {
            margin-bottom: 3.75rem;
            &__carousel {
                .slick-dots{
                    justify-content: center;
                    margin-top: 1.813rem;
                }
            }
        }
    }
    .search-results--null-page {
        margin-top: 1.4rem;
        margin-bottom: 0.5rem;
    }
    .search-results--null__description {
        a {
            transition: opacity 107ms ease-in-out;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &__AD {
        .search-results--wrapper {
            padding-left: 11rem;
            padding-right: 11rem;
            padding-bottom: 5.625rem;
        }
        @include media-query(small) {
            padding: 1rem;
            .search-results--wrapper {
                padding: 0;
            }
        }
        .search-results--null__title {
            font-weight: 300;
            color: $color-heading-black;
            letter-spacing: 1px;
            margin-bottom: 1.25rem;
            @include media-query(small) {
               text-align: center;
            }
        }
        .search-results--null-page {
            margin-top: 6rem;
            padding: 4rem 0;
            border: 1px solid $color-dark-gold;
            @include media-query(small) { 
                margin-top: 2rem;
                padding: 1rem;
            }
        }
        .null-page__subheading {
            font-size: 1.313rem;
            line-height: 1.313rem;
            color: $color-primary;
            font-weight: 400;
            @include media-query(small) { 
               font-size: 14px;
            }
        }
        .search-results--null__description {
            margin-top: 2rem;
            a {
                font-size: 1.313rem;
                line-height: 1.313rem;
                text-transform: none;
                color: $color-primary;
                font-weight: 400;
                @include media-query(small) { 
                    font-size: 14px;
                 }
            }
        }
        .content-asset {
            padding-bottom: 0;
        }
    }
    
}

@include media-query(small) {
    .search-results--null-page {
        .search-results--null__title {
            font-size: 21px;
        }
    }
    .search-results--null__help {
        margin-top: 2rem;
    }
    .search-results--null__description {
        text-align: center;
        font-weight: normal;
        font-size: $font-size-deci;
        margin-top: 21px;
    }
    .null-page {
        .module-container {
            margin-top: 40px;
            .product-list {
                margin-bottom: 2.5rem;
                &__carousel {
                    .slick-dots{
                        margin-top: 1.938rem;
                    }
                }
            }
        }
    }    
}
.search-results__main {
	.slick-arrow {
		svg {
			display: none;
		}
	}
}
.search-results--null-page {
    form.search-results--null__form {
        width: 48rem;
        margin: 0 auto;
        @include media-query(small) {
            width: 100%;
        }
    }
	.max-width--small {
		max-width: 90rem;
		p {
			&:nth-child(4) {
				text-align: center;
			}
		}
	}
	a {
		text-decoration: underline;
	}
}
