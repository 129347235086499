
.refinement__color,
.refinement__size {
    display: flex;
    flex-wrap: wrap;
}

.refinement__color {
	.swatch--color-large {
		@include media-query(small-up) {
			width: 1.5em;
			height: 1.5em;
		}
	}
}
    .refinement__list {
        .refinement__list {
            padding-left: 1rem;
        }

        &:not(:first-child) {
            margin-top: 1.125rem;
        }
    }

        .refinement__list-item {
            &:not(:last-child) {
                margin-bottom: 1.15em;
            }
            a.maincategory {
		    	&.searchResultPage {
		    		display: block;
		    	}
		    }
		    a.searchResultPage {
		    	display: none;
		    }
            
            .form-check-label {
            	font-weight: 400;
            	
            	&.set--checked {
            		font-weight: 600;
            	}
            }
            
            &.set--categories {
            	a.searchResultPage {
			    	display: block;
			    }
            }
        }

        .refinement__boolean-item {
            &:not(:last-child) {
                margin-bottom: .75em;
            }
        }

        .refinement__chip-item,
        .refinement__swatch-color-item {
            margin-right: .625rem;
            margin-bottom: .625rem;
        }

        .refinement__chip-anchor {

        }

    .applied-refinements__item {
        &:not(:last-child) {
            margin-right: .625rem;
            margin-bottom: .5rem;
        }
        &.refinement-bar__reset {
            font-weight: bold;
            button {
                padding-top: 0.375rem
            }
        }
        a.maincategory {
        	@include media-query(small-up) {
        		pointer-events: none;
        	}
        }
    }

@include media-query(small-up) {

    .refinement-bar {
        position: relative;
        flex-grow: 1;
    }

        .refinement-bar__title {
            margin-bottom: 1.75rem;
        }

        .refinement-bar__body {
            padding-top: 1.75rem;
        }

        .refinement {
            padding-bottom: 1.25rem;
            margin-bottom: 1.25rem;
            border-bottom: solid .0625rem $color-filter-border;
        }

            .refinement__content {
                margin-top: 1.5rem;
            }

        .applied-refinements__list {
            margin-top: 1.25rem;
            margin-bottom: 1.25rem;
        }
}

@include media-query(small) {
    .refinement-bar {
        z-index: 11;
        display: flex;
        flex-direction: column;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        background-color: $color-panel-background;
        box-shadow: none;

        &.refinement-bar--active {
            transition: transform $transition-speed-normal $transition-ease-bezier,
                        visibility 0s;
        }

        &:not(.refinement-bar--active) {
            visibility: hidden;
            transform: translateX(-100%);
            transition: transform $transition-speed-fast $transition-ease-in,
                        visibility 0s $transition-ease-in $transition-speed-fast;
        }
        .counter-category {
        	font-size: 0.813rem;
        	color: $color-grey-4;
        	font-weight: 400;
        }
    }

        .refinement-bar__head,
        .refinement-bar__footer {
            padding-top: 1rem;
            padding-bottom: 1rem;
            flex-shrink: 0;
        }

        .refinement-bar__head {
            border-bottom: solid .0625rem $color-grey-3;
            font-size: 1.25rem;
            line-height: 1.5rem;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            
            .refinement-bar__head-count {
            	font-weight: 400;
            }
        }

        .refinement-bar__footer {
            z-index: 1;
            border-top: 0;
            box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.1);
            padding-top: 0.875rem;
            padding-bottom: 0.75rem;
            flex-direction: row;
            text-align: center;
        }

        .refinement-bar__body {
            overflow-y: auto;
            flex-grow: 1;
        }

            .refinement {
                &:not(:last-child) {
                    border-bottom: none;
                }

                border-bottom: none;
            }

                .refinement__header {
                    position: relative;
                    padding-top: 1rem;
                    padding-bottom: 1rem;

                    &.refinement--active {
                        .refinement__header-icon {
                            transform: scale(-1);
                        }
                    }
                }

                .refinement__content {
                    padding-bottom: 1rem;

                    &.refinement--active {
                        display: none;
                    }
                }

                .refinement__icon-collapsed,
                .refinement__icon-expanded {
                    position: absolute;
                    right: 0;
                }

    .applied-refinements__list {
        margin-bottom: 2.25rem;
    }
}
.refinement__size.list--reset {
    display: block;
}
@include media-query(small){
.reset-filter{
    color:#1e1e1e;
    font-size: 16px;
    font-weight: 400;
}
}
@include media-query(small){
.filter-action-close{
    color: #000;
    border:none;
    background: none;
    margin-left: 22px;
}
.refinement-bar__footer.display--small-only.gutter--normal{
    display: flex;
}
.refinement-bar__close {
    border-right: 1px solid #d9d9d9;
    width: 50%;
}
.button--primary:hover:not([disabled]):not(.disabled){
    background-color: none;
}
.button:hover:not([disabled]):not(.disabled){
.button--primary-outline:hover:not([disabled]):not(.disabled){
    background-color: none;
}
}
.refinement-bar__close-filter{
    border:none;
    color:#999999;
    margin: 0;
    background: none;
    padding: 0;
    letter-spacing: 0;
}
.refinement-bar__apply {
    margin-left: inherit;
    width: 50%;
}
.refinement-bar__apply-filter{
    border: none; 
    padding: 0;
    letter-spacing: 0;
}
}

@include media-query(small){
.refinements {
        width: 44%;
        height: calc(100vh - 106px);
        background: #f9fafa;
     }
    .refinement__content-list{
        display: block;
    }
}
@include media-query(small){
.refinement__content {
    padding-bottom: 1rem;
    display: none;
    position: absolute;
    top: 57px;
    right: 0;
    width: 56%;
    height: calc(100% - 106px);
    overflow: auto;
    padding: 0.875rem 0.875rem 0;

    > ol.refinement__list {
        &.refinement__categories {
            width: 100%;
            margin-right: 0;   
            
            .refinement__list-item{
                &:last-child {
                    border-bottom: 0;
                }
            }
            
        }       

        ol {
            width: 100%;
            margin: 0;
            padding-left: 1.5rem;
        }
    }

    .form-check-label {
        padding: 0.875rem 0 0.875rem 1.9em;
        color: $color-grey-11;

        &:before, &:after {
            top: 0.875rem;
        }

        &:after {
            height: 1.25em;
            line-height: 1.25em;
        }
        
        span {
        	&.productCount {
        		color: $form-control-label-color;
        	}
        }
    }

    > .list-items {
        > .refinement__list-item:first-child, > .refinement__list-types:first-child {
            > .form-check-label {
                padding-top: 0;

                &:before, &:after {
                    top: 0;
                }
            }
        }
    }

    #priceslider {
        width: 183px;
    }
}
.refinement-bar__body {
    padding: 0px;
}
.refinement__content.refinement--active {
    display: block;
}
.refinement__header{
    color:#666666;
    background-color: #f9fafa;
    padding: .875rem 1.25rem;
    text-align: left;
    > span {
       color:#666666; 
    }
    .refinement__type {
        font-weight: $font-weight-lightbold;
    }
}
.refinement--active{
    display: block;
}
.refinement__header.refinement--active{
    display: flex;
    border-left: 3px solid #1e1e1e;
    color: black;
    padding: .875rem 1.25rem .875rem calc(1.25rem - 3px);
    background-color: #ffffff;

    .refinement__type {
        font-weight: normal;
    }
}
.refinement { 
    border:none;
}
.refinement__list-types{
    border-bottom: 1px solid #EaEaEc;
    padding: 0;
    margin-bottom: 0;
    margin-right: 0;

    &:not(:last-child) {
       margin-bottom: 0; 
    }
}
.refinement__list-item:not(:last-child) {
    margin-bottom: 0;
}
.list-items{
    margin-right: 0;
}
}
.refinement__content {
    .swatch--color-large {
        width: 1.313rem;
        height: 1.313rem;
    }
}

.swatch--color- { 
    &beige,
    &ecru {
        color:#F5F5DC;
    }
    &black {
        color:#000000;
    }
    &blue,
    &darkblue,
    &electricblue,
    &iceblue,
    &indigoblue,
    &inkblue,
    &lightblue,
    &midnightblue,
    &navyblue,
    &peacockblue,
    &powderblue,
    &royalblue,
    &seablue,
    &skyblue,
    &turqoiseblue,
    &aqua,
    &denim,
    &turquoise,
    &slate {
        color:#1979D9;
    }
    &brown,
    &darkbrown,
    &brick,
    &bronze,
    &camel,
    &coffee,
    &copper,
    &khaki,
    &rust,
    &sand,
    &tan {
        color:#964B00;
    }
    &gold {
        color:#FFD700;
    }
    &green,
    &darkgreen,
    &emerald,
    &emeraldgreen,
    &limegreen,
    &pistagreen,
    &sagegreen,
    &seagreen,
    &mint,
    &olive,
    &teal {
        color:#15C515;
    }
    &grey,
    &darkgrey,
    &lightgrey,
    &charcoal,
    &gunmetal,
    &mouse {
        color:#696969;
    }
    &maroon,
    &berry,
    &burgundy,
    &cherrywood,
    &cranberry,
    &plum,
    &rasberry,
    &wine {
        color:#800000;
    }
    &orange,
    &apricot,
    &burntorange,
    &citrus,
    &coral,
    &flame,
    &tangerine {
        color:#FFA500;
    }
    &peach,
    &peachpuff,
    &flesh,
    &natural,
    &nude {
        color:#FFAF94;
    }
    &pink,
    &darkpink,
    &hotpink,
    &lightpink,
    &onionpink,
    &rosegold,
    &rosepink,
    &whiteflesh,
    &blush,
    &fuschia,
    &magenta,
    &oldrose,
    &rosewood {
        color:#FD82C6;
    }
    &purple,
    &lilac,
    &taupe { 
        color:#B152ED;
    }
    &red,
    &red,
    &redshaded,
    &tomato,
    &crimson,
    &ruby {
        color:#DE3232;
    }
    &silver {
        color:#C0C0C0;
    }
    &white,
    &cream,
    &creamlime,
    &champagne,
    &ivory,
    &offwhite {
        color:#ffffff;
    }
    &yellow,
    &mango,
    &mustard,
    &ochre {
        color:#FFEF00;
    }
    &lime {color:#BCEE00;}
    &indigo {color:#61008B;}
    &aqua {color:#00C6E1;}
    &aquagreen {color:#15C515;}
}
