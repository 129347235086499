
.list-view-switch {
    font-size: $font-size-deci;
    @include media-query(small-up) {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
}
.list-view-switch__trigger:not(.set--active) {
    color: $color-grey-3;

}

    .list-view-switch__icon--selected {
        display: none;

        .set--active & {
            display: block;
        }
    }

    .list-view-switch__icon--unselected {
        .set--active & {
            display: none;
        }
    }
