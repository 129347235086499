
@supports (display: grid) {
    .product-grid {
        display: grid;
    }

        .product-grid__item {
            flex-basis: auto;
            max-width: none;
        }

        .search-results__footer {
            grid-column: 1/-1;
        }
}

@include media-query(medium-up) {
    .product-grid--view-grid {
        grid-template-columns: repeat(4,percentage(1/4));
    }

    .product-grid--view-feed {
        grid-template-columns: repeat(2,percentage(1/2));
    }

        .product-grid__item--interrupter {
            grid-row: span var(--interrupter-row-size, 2);
        }
}

@include media-query(small-up-to-medium) {
    .product-grid--view-grid {
        grid-template-columns: repeat(3,percentage(1/3));
    }

    .product-grid--view-feed {
        grid-template-columns: repeat(2,percentage(1/2));
    }
}

@include media-query(small-up) {
    @supports (display: grid) {
        .product-grid__item--interrupter {
            grid-column: span var(--interrupter-col-size, 1);
        }
    }
}

@include media-query(small) {
    .product-grid--view-grid {
        grid-template-columns: repeat(2,percentage(1/2));
    }

    .product-grid--view-feed {
        grid-template-columns: repeat(1, 100%);
    }
}
