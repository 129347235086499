

.search-results__sort-dropdown__close {
    display: none;
    position: fixed;
    bottom: 11.875rem;
    z-index: 2;
    right: 1.25rem;
    color: $color-white;
}
.search-results__toolbar-refinement {
    > span:first-child {
        position: relative;
    }
    &-badge {
        position: absolute;
        top: -.25rem;
        right: -1rem;
        min-width: 1rem;
        height: 1rem;
        padding-right: .25em;
        padding-left: .25em;
        background-color: $color-pink;
        border-radius: 1rem;
        color: $color-white;
        font-size: .5625rem; //Intentionally not using a variable.
        font-weight: $font-weight-bold;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1rem;
    }
}
@include media-query(small-up) {
    .search-results__nav {
        max-width: 38rem;
        margin-left: auto;
        margin-right: auto;
    }

    .search-results__toolbar {
        padding-top: 1.125rem;
    }
}

@include media-query(small) {
    .search-results__toolbar {
        margin-top: 1rem;
        margin-bottom: 2rem;
        hr {
            margin: 0 -1.25rem;
        }
        .search-results__sort-dropdown {
            padding-top: .625rem;
            padding-bottom: .625rem;
            border-left: .0625rem solid $color-black;
            border-right: .0625rem solid $color-black;
        }
        .product-list__view {
            align-items: center;
        }
    }
    .search-results__sort-dropdown {
        &.toggle--active {
            .search-results__sort-dropdown__close {
                bottom: 20rem;
                display: block;
                font-size: 24px;
            }
        }
    }

    .search-results__toolbar-count {
        margin-bottom: 1.25rem;
    }
}
